import React, { useState } from "react";
import ROLE from "../common/role";
import { IoMdClose } from "react-icons/io";
import SummaryApi from "../common";
import { toast } from "react-toastify";

const ChangeUserRole = ({ name, email, role, onClose, userId, callFunc }) => {
  const [userRole, setUserRole] = useState(role);

  const handleOnChangeSelect = (e) => {
    setUserRole(e.target.value);
  };

  const updateUserRole = async () => {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
  if (!token) {
    console.error("No token found. User is not authenticated.");
    return;
  }
    const fetchResponse = await fetch(SummaryApi.update_user.url, {
      method: SummaryApi.update_user.method,
      credentials: "include",
      headers: {
        "Authorization": `Bearer ${token}`, // Include the token in the request headers
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        role: userRole,
      }),
    });

    const responseData = await fetchResponse.json();

    if(responseData.success){
        toast.success(responseData.message)
        onClose()
        callFunc()
    }

    console.log("role_updated", responseData);
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 w-full z-10 flex justify-between items-center bg-slate-200 bg-opacity-40">
      <div className="mx-auto bg-white shadow-md p-4 w-full max-w-sm">
        <button className="block ml-auto" onClick={onClose}>
          <IoMdClose />
        </button>
        <h1 className="pb-4 text-lg font-medium">Change User Role</h1>
        <p>Name: {name}</p>
        <p>Emai: {email}</p>

        <div className="flex items-center justify-between my-4">
          <p>Role :</p>

          <select
            className="border px-4 py-1"
            value={userRole}
            onChange={handleOnChangeSelect}
          >
            {Object.values(ROLE).map((el) => {
              return <option value={el}>{el}</option>;
            })}
          </select>
        </div>

        <button
          className="w-fit mx-auto block border py-1 px-3 rounded-full bg-pink-500 text-white hover:bg-pink-700"
          onClick={updateUserRole}
        >
          Change Role
        </button>
      </div>
    </div>
  );
};

export default ChangeUserRole;
