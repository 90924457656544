import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
      <CategoryList />
      <BannerProduct />

      <HorizontalCardProduct category={"wears"} heading={"Top's Wears"}/>
      <HorizontalCardProduct category={"sneakers"} heading={"Popular's Sneakers"}/>

      <VerticalCardProduct category={"footwears"} heading={"Popular's Footwears"}/>
      <VerticalCardProduct category={"kitchen"} heading={"Popular's Kitchen Items"}/>
      <VerticalCardProduct category={"shoe"} heading={"Popular's Shoes Items"}/>
    </div>
  )
}

export default Home