import React, { useEffect, useState } from "react";
import UploadProduct from "../components/UploadProduct";
import SummaryApi from "../common";
import AdminProductCard from "../components/AdminProductCard";

const AllProduct = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);

  const [allProduct, setAllProduct] = useState([]);

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryApi.all_product.url);

    const dataResponse = await response.json();

    setAllProduct(dataResponse?.data || []);
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const deleteProduct = async (productId) => {
    const token = localStorage.getItem("authToken"); // Get the token from local storage
    if (!token) {
      console.error("No token found. User is not authenticated.");
      return;
    }
    try {
      const response = await fetch(
        `${SummaryApi.deleteProduct.url}/${productId}`,
        {
          method: SummaryApi.deleteProduct.method,
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
            "Content-Type": "application/json",
          },
        }
      );

      const dataResponse = await response.json();
      if (dataResponse.success) {
        alert("Product deleted successfully");
        fetchAllProduct(); // Refresh the list after deletion
      } else {
        alert("Failed to delete product: " + dataResponse.message);
      }
    } catch (error) {
      alert("Error deleting product: " + error.message);
    }
  };
  return (
    <div>
      <div className="bg-white py-2 px-4 flex items-center justify-between">
        <h2 className="font-bold text-lg ">All Product</h2>
        <button
          className="border-2 py-1 px-3 border-pink-600 text-pink-600 rounded-full hover:bg-pink-600 hover:text-white transition-all"
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>

      {/* All the product */}
      <div className="mx-auto justify-center flex items-center flex-wrap gap-5 py-4 h-[calc(100vh-200px)] overflow-y-scroll">
        {allProduct.map((product, index) => {
          return (
            <AdminProductCard
              data={product}
              key={index + "allProduct"}
              fetchProduct={fetchAllProduct}
              deleteProduct={deleteProduct}
            />
          );
        })}
      </div>

      {/**Upload Product Pop up */}
      {openUploadProduct && (
        <UploadProduct
          onClose={() => setOpenUploadProduct(false)}
          fetchData={fetchAllProduct}
        />
      )}
    </div>
  );
};

export default AllProduct;
