const productCategory = [
    {id:1, label: "Wears", value: "wears"},
    {id:2, label: "Underwears", value: "underwears"},
    {id:3, label: "Footwears", value: "footwears"},
    {id:4, label: "Bags", value: "bags"},
    {id:5, label: "Kitchen", value: "kitchen"},
    {id:6, label: "Sneakers", value: "sneakers"},
    {id:7, label: "Shoes", value: "shoe"},
    {id:8, label: "LadiesWears", value: "ladies wears"},
    {id:9, label: "Unisex", value: "unisex"},
    {id:10, label: "Kiddiesstore", value: "kiddies store"},
    {id:11, label: "Men'sworld", value: "mens's world"},
    {id:12, label: "Belts", value: "belts"},
    {id:13, label: "Denims", value: "denims"},
    {id:14, label: "Totebag", value: "tote bag"},
    {id:15, label: "Bottles", value: "bottles"},
    {id:16, label: "Contentkits", value: "content kits"},
    {id:17, label: "Sneakers", value: "sneakers"},
    {id:18, label: "Ladiesfootwears", value: "ladies footwear"},
]

export default productCategory