import React, { useEffect } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {Link, Outlet, useNavigate} from "react-router-dom"
import ROLE from "../common/role";

const AdminPanel = () => {
  const user = useSelector((state) => state?.user?.user);

  const navigate = useNavigate()


  useEffect(() =>{
    if(user?.role !== ROLE.ADMIN){
      navigate("/")
    }
  }, [user])

  return (
    <div className="min-h-[calc(100vh-120px)] md:flex ">

      <aside className="bg-white min-h-full w-full max-w-60 customShadow md:block hidden">
        <div className="h-32 flex justify-center items-center flex-col">
          <div className="text-5xl cursor-pointer relative flex justify-center">
            {user?.profilePic ? (
              <img
                src={user?.profilePic}
                alt="profileDP"
                className="w-20 h-20 rounded-full"
              />
            ) : (
              <FaRegUserCircle />
            )}
          </div>
          <p className="capitalize text-lg font-semibold">{user?.name}</p>
          <p className="text-sm">{user?.role}</p>

        </div>
            {/***navigation */}
        <div>
             <nav className="grid p-4">
              <Link to={"all-users"} className="px-2 py-1 hover:bg-pink-100">All Customers</Link>
              <Link to={"all-product"} className="px-2 py-1 hover:bg-pink-100">Product</Link>
             </nav>
        </div>
      </aside>

      <main className="w-full h-full p-2">
        <Outlet />
      </main>
    </div>
  );
};

export default AdminPanel;
