const backendDomain = 'https://didsstore.onrender.com';
// const backendDomain = 'http://localhost:8080';


//https://didsstore.onrender.com

const SummaryApi = {
  signUp: {
    url: `${backendDomain}/api/signup`,
    method: "POST"
  },
  signIn: {
    url: `${backendDomain}/api/signin`,
    method: "POST"
  },
  current_user: {
    url: `${backendDomain}/api/user-details`,
    method: "GET"
  },
  logout_user: {
    url: `${backendDomain}/api/userLogout`,
    method: "GET"
  },
  all_user: {
    url: `${backendDomain}/api/all-user`,
    method: "GET"
  },
  update_user: {
    url: `${backendDomain}/api/update-user`,
    method: "POST"
  },
  upload_product: {
    url: `${backendDomain}/api/upload-product`,
    method: "POST"
  },
  all_product: {
    url: `${backendDomain}/api/get-product`,
    method: "GET"
  },
  update_product: {
    url: `${backendDomain}/api/update-product`,
    method: "POST"
  },
  categoryProduct: {
    url: `${backendDomain}/api/get-CategoryProduct`,
    method: "GET"
  },
  categoryWiseProduct: {
    url: `${backendDomain}/api/category-product`,
    method: "POST"
  },
  productDetails: {
    url: `${backendDomain}/api/product-details`,
    method: "POST"
  },
  addToCartProduct: {
    url: `${backendDomain}/api/addtocart`,
    method: "POST"
  },
  addToCartProductCount: {
    url: `${backendDomain}/api/countAddToCartProduct`,
    method: "GET"
  },
  addToCartProductView: {
    url: `${backendDomain}/api/view-cart-product`,
    method: "GET"
  },
  updateCartProduct: {
    url: `${backendDomain}/api/update-cart-product`,
    method: "POST"
  },
  DeleteCartProduct: {
    url: `${backendDomain}/api/delete-cart-product`,
    method: "POST"
  },
  searchProduct: {
    url: `${backendDomain}/api/search`,
    method: "GET"
  },
  
  filterProduct: {
    url: `${backendDomain}/api/filter-product`,
    method: "POST"
  },

  deleteProduct: {
    url: `${backendDomain}/api/delete-product`,
    method: "DELETE"
  },

};

export default SummaryApi;
