import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import scrollTop from '../helpers/scrollTop';
import displayNGNCurrency from '../helpers/displayCurrency';
import Context from '../context';
import addToCart from '../helpers/addToCart';

const VerticalCard = ({loading, data}) => {
    const loadingList = new Array(13).fill(null);
    const { fetchUserAddToCartCount } = useContext(Context);

    const handleAddToCart = async (e, id) => {
      await addToCart(e, id);
      fetchUserAddToCartCount();
    };
    
    return (
      <div className="grid grid-cols-[repeat(auto-fit,minmax(290px,230px))] justify-center md:justify-between md:gap-4 overflow-x-scroll scrollbar-none transition-all ">
          {loading
            ? loadingList.map((product, index) => {
                return (
                  <div
                    className="w-full min-w-[230px] md:min-w-[320px] max-w-[230px] md:max-w-[320px] bg-white rounded-sm shadow"
                    key={index}
                  >
                    <div className="bg-slate-200 h-48 min-w-[280px] md:min-w-[145px] flex md:justify-center justify-between items-center p-1 py-3 animate-pulse"></div>
                    <div className="p-4 grid gap-3">
                      <h2 className=" p-1 animate-pulse rounded-full"></h2>
                      <p className="capitalize text-slate-500 p-1 animate-pulse rounded-full"></p>
                      <div className="flex gap-3">
                        <p className=" bg-slate-200 font-medium w-full p-1 animate-pulse rounded-full"></p>
                        <p className="text-slate-500 w-full bg-slate-200 line-through p-1 animate-pulse rounded-full"></p>
                      </div>
                      <button className="text-sm bg-slate-200  text-white px-3 py-2 p-1 animate-pulse rounded-full"></button>
                    </div>
                  </div>
                );
              })
            : data.map((product, index) => {
                return (
                  <Link
                    to={"/product/" + product?._id}
                    className="w-full min-w-[230px] md:min-w-[300px] max-w-[230px] md:max-w-[300px] bg-white rounded-sm shadow"
                    key={index}
                    onClick={scrollTop}
                  >
                    <div className="bg-slate-200 h-48 p-4 min-w-[230px] md:min-w-[145px] flex justify-center items-center">
                      <img
                        src={product.productImage[0]}
                        alt="productImg"
                        className="object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply"
                      />
                    </div>
                    <div className="p-4 grid gap-3">
                      <h2 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black">
                        {product?.productName}
                      </h2>
                      <p className="capitalize text-slate-500">
                        {product?.category}
                      </p>
                      <div className="flex gap-3">
                        <p className="text-pink-600 font-medium">
                          {displayNGNCurrency(product?.sellingPrice)}
                        </p>
                        <p className="text-slate-500 line-through">
                          {displayNGNCurrency(product?.price)}
                        </p>
                      </div>
                      <button
                        className="text-sm bg-pink-600 hover:bg-pink-700 text-white px-3 py-0.5 rounded-full"
                        onClick={(e) => handleAddToCart(e, product?._id)}
                      >
                        Add to Cart
                      </button>
                    </div>
                  </Link>
                );
              })}
        </div>
    )
}

export default VerticalCard