const uploadImage = async (image) => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME_CLOUDINARY}/image/upload`;
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "didiTrend");
    // formData.append("transformation", JSON.stringify({
    //     overlay: {
    //         font_family: "Arial",
    //         font_size: 30,
    //         text: encodeURIComponent("Didi's Trend"),
    //         color: "white",
    //         opacity: 80
    //     },
    //     gravity: "south_east",
    //     width: 200,
    //     crop: "scale"
    // }));

    try {
        const response = await fetch(url, {
            method: "POST",
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to upload image');
        }

        return await response.json();
    } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
    }
};

export default uploadImage;
