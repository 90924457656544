import React, { useContext, useEffect, useState } from "react";
import SummaryApi from "../common";
import Context from "../context";
import displayNGNCurrency from "../helpers/displayCurrency";
import { MdDelete } from "react-icons/md";

const Cart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
 
  const loadingCart = new Array(4).fill(null);
  const { fetchUserAddToCartCount } = useContext(Context);

  const fetchData = async () => {
    const token = localStorage.getItem("authToken"); // Get the token from local storage
    if (!token) {
      console.error("No token found. User is not authenticated.");
      return;
    }

    try {
      const response = await fetch(SummaryApi.addToCartProductView.url, {
        method: SummaryApi.addToCartProductView.method,
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
          "Content-Type": "application/json",
        },
      });

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        setData(responseData.data); // Set the response data
      } else {
        console.error("Failed to fetch data:", responseData.message);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  const handleLoading = async () => {
    await fetchData();
  };

  useEffect(() => {
    setLoading(true);
    handleLoading();
    setLoading(false);
  }, []);

  const increaseQty = async (id, qty) => {
    const token = localStorage.getItem("authToken"); // Get the token from local storage
    if (!token) {
      console.error("No token found. User is not authenticated.");
      return;
    }

    const response = await fetch(SummaryApi.updateCartProduct.url, {
      method: SummaryApi.updateCartProduct.method, // Fix the extra comma
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the request headers
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: id,
        quantity: qty + 1, // Fix the JSON.stringify syntax
      }),
    });

    const responseData = await response.json(); // Fix the method call with parentheses

    if (responseData.success) {
      // Fix the typo here
      fetchData();
    }
  };
  const decreaseQty = async (id, qty) => {
    const token = localStorage.getItem("authToken"); // Get the token from local storage
    if (!token) {
      console.error("No token found. User is not authenticated.");
      return;
    }
    if (qty >= 2) {
      const response = await fetch(SummaryApi.updateCartProduct.url, {
        method: SummaryApi.updateCartProduct.method, // Fix the extra comma
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: id,
          quantity: qty - 1, // Fix the JSON.stringify syntax
        }),
      });

      const responseData = await response.json(); // Fix the method call with parentheses

      if (responseData.success) {
        // Fix the typo here
        fetchData();
      }
    }
  };

  const deleteItem = async (id) => {
    const token = localStorage.getItem("authToken"); // Get the token from local storage
    if (!token) {
      console.error("No token found. User is not authenticated.");
      return;
    }

    const response = await fetch(SummaryApi.DeleteCartProduct.url, {
      method: SummaryApi.DeleteCartProduct.method,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the request headers
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: id,
      }),
    });

    const responseData = await response.json(); // Fix the method call with parentheses

    if (responseData.success) {
      // Fix the typo here
      fetchData();
      fetchUserAddToCartCount();
    }
  };

  const totalQty = data.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );
  const totalPrice = data.reduce(
    (preve, curr) => preve + curr.quantity * curr?.productId?.sellingPrice,
    0
  );

  console.log(data);

  const handleCheckout = () => {
    const orderDetails = data
      .map((product) => {
        const productName = product?.productId?.productName;
        const quantity = product?.quantity;
        const price = displayNGNCurrency(
          product?.productId?.sellingPrice * quantity
        );
        return `Product: ${productName}, Quantity: ${quantity}, Price: ${price}`;
      })
      .join("\n");

    const totalOrderPrice = displayNGNCurrency(totalPrice);

    const message = `Hello Didi\n\n Order Summary:\n\n${orderDetails}\n\nTotal Price: ${totalOrderPrice}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/+2347067809770?text=${encodedMessage}`;

    window.location.href = whatsappLink;
  };

  return (
    <div className="container mx-auto">
      <div className="text-center text-lg my-3">
        {data.length === 0 && !loading && (
          <p className="bg-white py-5">No Data</p>
        )}
      </div>

      <div className="flex gap-10 flex-col lg:flex-row lg:justify-between p-4">
        <div className="w-full max-w-3xl">
          {/* View Product */}
          <div>
            {loading
              ? loadingCart.map((el, index) => (
                  <div
                    key={index} // Use index as the key here, because 'el' is null
                    className="w-full bg-slate-300 h-32 my-2 border border-slate-300 animate-pulse rounded "
                  ></div>
                ))
              : data.map((product) => (
                  <div
                    key={product?._id + "Add to cart loading"}
                    className="w-full bg-white h-38 my-2 border border-slate-300 rounded grid grid-cols-[120px,1fr]"
                  >
                    <div className="w-28 h-38 bg-slate-200">
                      <img
                        src={product?.productId?.productImage[0]}
                        alt="product"
                        className="w-full h-full object-scale-down mix-blend-multiply"
                      />
                    </div>

                    <div className="px-4 py-2 relative">
                      {/* Delete Product */}
                      <div
                        className="absolute right-0 text-pink-600 rounded-full p-2 hover:bg-pink-600 hover:text-white cursor-pointer"
                        onClick={() => deleteItem(product?._id)}
                      >
                        <MdDelete />
                      </div>
                      <h2 className="text-lg lg:text-xl line-clamp-1">
                        {product?.productId?.productName}
                      </h2>
                      <p className="capitalize text-slate-500 ">
                        {product?.productId?.category}
                      </p>
                      <div className="flex items-center justify-between">
                        <p className="text-pink-600 font-medium text-lg">
                          {displayNGNCurrency(product?.productId?.sellingPrice)}
                        </p>
                        <p className="text-slate-600 font-semibold text-lg">
                          {displayNGNCurrency(
                            product?.productId?.sellingPrice * product?.quantity
                          )}
                        </p>
                      </div>

                      <div className="flex items-center gap-3 mt-2">
                        <button
                          className="border border-pink-600 hover:bg-pink-600 hover:text-white text-pink-600 w-6 h-6 flex justify-center items-center rounded "
                          onClick={() =>
                            decreaseQty(product?._id, product?.quantity)
                          }
                        >
                          -
                        </button>
                        <span>{product?.quantity}</span>
                        <button
                          className="border border-pink-600 hover:bg-pink-600 hover:text-white text-pink-600 w-6 h-6 flex justify-center items-center rounded "
                          onClick={() =>
                            increaseQty(product?._id, product?.quantity)
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        {/* Total Product in Cart */}
        <div className="mt-5 lg:mt-0 w-full max-w-sm">
          {loading ? (
            <div className="h-36 bg-slate-200 animate-pulse"></div>
          ) : (
            <div className="h-36 bg-white">
              <h2 className="text-white bg-pink-600 px-4 py-1">Summary</h2>
              <div className="flex items-center justify-between px-4 font-medium text-lg text-slate-600 gap-2">
                <p>Quantity</p>
                <p>{totalQty}</p>
              </div>
              <div className="flex items-center justify-between px-4 font-medium text-lg text-slate-600 gap-2">
                <p>Total Price</p>
                <p>{displayNGNCurrency(totalPrice)}</p>
              </div>

              <button
                className="bg-blue-600 p-2 text-white w-full"
                onClick={handleCheckout}
              >
                CheckOut
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
