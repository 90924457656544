import SummaryApi from "../common";

const fetchCategoryWiseProduct = async (category) => {
  // const token = localStorage.getItem("authToken"); // Get the token from local storage
  // if (!token) {
  //   console.error("No token found. User is not authenticated.");
  //   return;
  // }

  const response = await fetch(SummaryApi.categoryWiseProduct.url, {
    method: SummaryApi.categoryWiseProduct.method,
    headers: {
      //"Authorization": `Bearer ${token}`, // Include the token in the request headers
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      category: category,
    }),
  });

  const responseData = await response.json(); // Parsing the JSON response
  return responseData; // Return the parsed data
};

export default fetchCategoryWiseProduct;
