import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import productCategory from "../helpers/productCategory";
import VerticalCard from "../components/VerticalCard";
import SummaryApi from "../common";

const CategoryProduct = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);
  const urlCategoryListingInArray = urlSearch.getAll("category");

  const urlCategoryListObject = {};
  urlCategoryListingInArray.forEach((el) => {
    urlCategoryListObject[el] = true;
  });
  const [selectCategory, setSelectCategory] = useState(urlCategoryListObject);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [sortBy, setSortBy] = useState('')

  const fetchData = async () => {
    try {
      const response = await fetch(SummaryApi.filterProduct.url, {
        method: SummaryApi.filterProduct.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category: filterCategoryList,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse = await response.json();
      setData(dataResponse?.data || []);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleSelectCategory = (e) => {
    const { name, value, checked } = e.target;

    setSelectCategory((preve) => {
      return {
        ...preve,
        [value]: checked,
      };
    });
  };

  useEffect(() => {
    fetchData();
  }, [filterCategoryList]);

  useEffect(() => {
    const arrayOfCategory = Object.keys(selectCategory)
      .map((categoryKeyName) => {
        if (selectCategory[categoryKeyName]) {
          return categoryKeyName;
        }
        return null;
      })
      .filter((el) => el);

    setFilterCategoryList(arrayOfCategory);

    //Format for url change on the chekbox
    const urlFormat = arrayOfCategory.map((el, index) => {
      if (arrayOfCategory.length - 1 === index) {
        return `category=${el}`;
      }
      return `category=${el}&&`;
    });

    navigate("/product-category?" + urlFormat.join(""));
  }, [selectCategory]);

  const handleOnChangeSortBy = (e) => {
    const {value} = e.target

    setSortBy(value)

    if(value === 'asc'){
      setData(preve => preve.sort((a,b) => a.sellingPrice - b.sellingPrice))
    }
    if(value === 'dsc'){
      setData(preve => preve.sort((a,b) => b.sellingPrice - a.sellingPrice))
    }
  }

  useEffect(() => {

  }, [sortBy])

  return (
    <div className="container mx-auto p-4">
      {/* DeskTop Version */}
      <div className="gridcol gap-4 hidden">
        {/* LeftSide */}
        <div className="bg-white p-2 min-h-[calc(100vh-120px)] overflow-y-scroll">
          {/* sort by */}
          <div className="">
            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
              Sort By
            </h3>

            <form className="text-sm flex-col gap-2 py-2">
              <div className="flex items-center gap-3">
                <input type="radio" name="sortBy" checked={sortBy === 'asc'} value={"asc"}  onChange={handleOnChangeSortBy}/>
                <label>Price - High to Low</label>
              </div>

              <div className="flex items-center gap-3">
                <input type="radio" name="sortBy" value={"dsc"} checked={sortBy === 'dsc'}  onChange={handleOnChangeSortBy}/>
                <label>Price - Low to High</label>
              </div>
            </form>
          </div>
          {/* filter by */}
          <div className="">
            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
              Filter By
            </h3>

            <form className="text-sm flex-col gap-2 py-2">
              {productCategory.map((categoryName, index) => {
                return (
                  <div className="flex items-center gap-3" key={index}>
                    <input
                      type="checkbox"
                      name={"category"}
                      checked={selectCategory[categoryName?.value]}
                      id={categoryName?.value}
                      value={categoryName?.value}
                      onChange={handleSelectCategory}
                    />
                    <label htmlFor={categoryName?.value}>
                      {categoryName?.value}
                    </label>
                  </div>
                );
              })}
            </form>
          </div>
        </div>

        {/* RightSide */}
        <div className="px-4">
          <p className="font-medium text-slate-800 text-lg mt-2">
            Search Result: {data.length}
          </p>
          <div className="min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)] scrollbar-none">
          {data.length !== 0 && <VerticalCard data={data} loading={loading} />}
        </div>
        </div>
        
      </div>
      {/* Mobile Version */}
      <div className="flex flex-col gap-4 lg:hidden">
        {/* Top Side */}
        <div className="bg-white p-2 min-h-2xl overflow-y-scroll">
          {/* sort by */}
          <div className="">
            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
              Sort By
            </h3>

            <form className="text-sm flex-col gap-2 py-2">
              <div className="flex items-center gap-3">
                <input type="radio" name="sortBy" checked={sortBy === 'asc'} value={"asc"}  onChange={handleOnChangeSortBy}/>
                <label>Price - High to Low</label>
              </div>

              <div className="flex items-center gap-3">
                <input type="radio" name="sortBy" value={"dsc"} checked={sortBy === 'dsc'}  onChange={handleOnChangeSortBy}/>
                <label>Price - Low to High</label>
              </div>
            </form>
          </div>
          {/* filter by */}
          <div className="">
            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
              Filter By
            </h3>

            <form className="text-sm flex-col gap-2 py-2">
              {productCategory.map((categoryName, index) => {
                return (
                  <div className="flex items-center gap-3" key={index}>
                    <input
                      type="checkbox"
                      name={"category"}
                      checked={selectCategory[categoryName?.value]}
                      id={categoryName?.value}
                      value={categoryName?.value}
                      onChange={handleSelectCategory}
                    />
                    <label htmlFor={categoryName?.value}>
                      {categoryName?.value}
                    </label>
                  </div>
                );
              })}
            </form>
          </div>
        </div>

        {/* Bottom Side */}
        <div className="px-4">
          <p className="font-medium text-slate-800 text-lg mt-2">
            Search Result: {data.length}
          </p>
          <div className="min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)] scrollbar-none">
          {data.length !== 0 && <VerticalCard data={data} loading={loading} />}
        </div>
        </div>
        
      </div>
    </div>
  );
};

export default CategoryProduct;
