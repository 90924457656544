import SummaryApi from "../common";
import {toast} from "react-toastify"

const addToCart = async (e, id) => {
  e?.stopPropagation();
  e?.preventDefault()

  const token = localStorage.getItem("authToken"); // Retrieve the token from localStorage

  if (!token) {
    console.error("No token found. User is not authenticated.");
    return;
  }

  const response = await fetch(SummaryApi.addToCartProduct.url,{
    method: SummaryApi.addToCartProduct.method,
    credentials: 'include',
    headers: {
      'Authorization': `Bearer ${token}`, // Include the token in the request headers
      'Content-Type': 'application/json'
    },
    body : JSON.stringify(
      {productId : id}
    )
  })

  const responseData = await response.json()

  if(responseData.success){
    console.log("Product added to cart:", responseData);
    toast.success(responseData.message)
  }
  if(responseData.error){
    console.error("Failed to add product to cart:", responseData.message);
    toast.error(responseData.message)
  }

  return responseData
};

export default addToCart;
