import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import productCategory from "../helpers/productCategory";
import { FaCloudUploadAlt } from "react-icons/fa";
import uploadImage from "../helpers/uploadImage";
import DisplayImage from "./DisplayImage";
import { MdDelete } from "react-icons/md";
import SummaryApi from "../common";
import { toast } from "react-toastify";

const AdminEditProduct = ({
onClose,
ProductData,
fetchProduct
}) => {
  const [data, setData] = useState({
    ...ProductData,
    productName: ProductData?.productName,
    brandName: ProductData?.brandName,
    category: ProductData?.category || [],
    productImage: ProductData?.productImage || [],
    description: ProductData?.description,
    price: ProductData?.price,
    sellingPrice: ProductData?.sellingPrice,
  });

  //const [uploadProductImageInput, setUploadProductImageInput] = useState("");

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);

  const [fulllScreenImage, setFulllScreenImage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
    //console.log(name, value); // Ensure console.log is fully typed
  };

  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];
    const uploadImageCloudinary = await uploadImage(file);
    setData((prevData) => ({
      ...prevData,
      productImage: [...prevData.productImage, uploadImageCloudinary.url],
    }));
  };

  const handleDeleteProductImage = async (index) => {
    console.log("Image Index", index);

    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);

    setData((prevData) => ({
      ...prevData,
      productImage: [newProductImage],
    }));
  };

  // Upload Product
  const handleSubmitProduct = async (e) => {

    e.preventDefault();

    const token = localStorage.getItem("authToken"); // Get the token from local storage
  if (!token) {
    console.error("No token found. User is not authenticated.");
    return;
  }

    const response = await fetch(SummaryApi.update_product.url, {
      method: SummaryApi.update_product.method,
      credentials: "include",
      headers: {
        "Authorization": `Bearer ${token}`, // Include the token in the request headers
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (responseData.success) {
      toast.success(responseData?.message);
      onClose()
      fetchProduct()
    }

    if (responseData.error) {
      toast.error(responseData?.message);
    }
  };
  return (
    <div className="fixed bg-slate-200 bg-opacity-35 w-full h-full top-0 left-0 right-0 bottom-0 flex justify-center items-center">
      <div className="bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden">
        <div className="flex justify-between items-center pb-2">
          <h2 className="font-bold text-lg">Edit Product</h2>
          <div
            className="w-fit ml-auto text-2xl hover:text-pink-600 cursor-pointer"
            onClick={onClose}
          >
            <IoMdClose />
          </div>
        </div>

        <form
          className="grid p-4 gap-2 overflow-y-scroll h-full pb-5"
          onSubmit={handleSubmitProduct}
        >
          <label htmlFor="productName" className="mt-3">
            Product Name :
          </label>
          <input
            type="text"
            id="productName"
            placeholder="enter your product name"
            name="productName"
            value={data.productName}
            onChange={handleOnChange}
            className="p-3 bg-slate-100 border rounded"
            required
          />
          <label htmlFor="brandName" className="mt-3">
            Brand Name :
          </label>
          <input
            type="text"
            id="brandName"
            placeholder="enter your brand name"
            name="brandName"
            value={data.brandName}
            onChange={handleOnChange}
            className="p-3 bg-slate-100 border rounded"
            required
          />

          <label htmlFor="category" className="mt-3">
            Category :
          </label>
          <select
            required
            value={data.category}
            name="category"
            onChange={handleOnChange}
            className="p-3 bg-slate-100 border rounded"
          >
            <option value="">Select Category</option>
            {productCategory.map((el, index) => {
              return (
                <option value={el.value} key={el.value + index}>
                  {el.label}
                </option>
              );
            })}
          </select>

          <label htmlFor="productImage" className="mt-3">
            Product Image :
          </label>
          <label htmlFor="uploadImageInput">
            <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer">
              <div className="text-slate-500 flex flex-col justify-center items-center gap-2">
                <span className="text-4xl">
                  <FaCloudUploadAlt />
                </span>
                <p className="text-sm">Upload Product Image</p>
                <input
                  type="file"
                  id="uploadImageInput"
                  className="hidden"
                  onChange={handleUploadProduct}
                />
              </div>
            </div>
          </label>

          <div>
            {data?.productImage[0] && data.productImage.length > 0 ? (
              <div className="flex items-center gap-2">
                {data.productImage.map((el, index) => {
                  return (
                    <div className="relative group">
                      <img
                        key={index} // Provide a unique key for each child element
                        src={el}
                        alt="ProductImg"
                        width={80}
                        height={80}
                        className="bg-slate-100 border cursor-pointer"
                        onClick={() => {
                          setOpenFullScreenImage(true);
                          setFulllScreenImage(el);
                        }} // open image in full screen when clicked
                      />
                      <div
                        className="absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer"
                        onClick={() => handleDeleteProductImage(index)}
                      >
                        <MdDelete />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-pink-600 text-xs">
                No product images available
              </p> // Render this if there are no images
            )}
          </div>

          <label htmlFor="price" className="mt-3">
            Price:
          </label>
          <input
            type="number"
            id="price"
            placeholder="enter your price here"
            name="price"
            value={data.price}
            onChange={handleOnChange}
            className="p-3 bg-slate-100 border rounded"
            required
          />

          <label htmlFor="sellingPrice" className="mt-3">
            Selling Price:
          </label>
          <input
            type="number"
            id="sellingPrice"
            placeholder="enter your price here"
            name="sellingPrice"
            value={data.sellingPrice}
            onChange={handleOnChange}
            className="p-3 bg-slate-100 border rounded"
          />

          <label htmlFor="description" className="mt-3">
            Product Description:
          </label>
          <textarea
            className="h-20 bg-slate-100 border rounded resize-none p-1"
            placeholder="enter your product description here:"
            rows={3}
            onChange={handleOnChange}
            name="description"
            value={data.description}
            required
          ></textarea>

          <button className="px-3 py-3 bg-pink-600 text-white mb-5 hover:bg-pink-800 font-bold">
            Update Product
          </button>
        </form>
      </div>

      {/* diplay image fullscreen */}
      {openFullScreenImage && (
        <DisplayImage
          onClose={() => setOpenFullScreenImage(false)}
          imgUrl={fulllScreenImage}
        />
      )}
    </div>
  );
}

export default AdminEditProduct