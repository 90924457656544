import React from 'react'

const Footer = () => {
  return (
    <footer className='bg-slate-200'>
      <div className='container p-4 mx-auto'>
      <p className='text-center font-bold text-pink-800' title="Chat on WhatsApp">Revamp your clothing line with Didi's Trend</p>
      </div>
    </footer>
  )
}

export default Footer