import React from "react";

const Logo = ({ w, h }) => {
  return (
    <img
      src="\logo.png"
      alt="Logo"
      style={{ width: `${w}px`, height: `${h}px` }}
      className="object-contain"
    />
  );
};

export default Logo;
