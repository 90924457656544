import React, { useContext, useEffect, useRef, useState } from "react";
import fetchCategoryWiseProduct from "../helpers/fetchCategoryWiseProduct";
import displayNGNCurrency from "../helpers/displayCurrency";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import addToCart from "../helpers/addToCart";
import Context from "../context";

const HorizontalCardProduct = ({ category, heading }) => {
  const [data, setData] = useState([]);
  console.log("product data", data)
  const [loading, setLoading] = useState(true);
  const loadingList = new Array(13).fill(null);

  const [scroll, setScroll] = useState(0);
  const scrollElement = useRef();

  const { fetchUserAddToCartCount } = useContext(Context)

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id)
    fetchUserAddToCartCount() 
  }

  const fetchData = async () => {
    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(category);
    if (!categoryProduct || categoryProduct.error) {
      throw new Error(categoryProduct.message || 'Failed to fetch products');
    }
    setLoading(false);

    setData(categoryProduct?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const scrollRight = () => {
    scrollElement.current.scrollLeft += 300;
  };
  const scrollLeft = () => {
    scrollElement.current.scrollLeft -= 300;
  };

  return (
    <div className="container mx-auto px-4 my-4 relative">
      <h2 className="text-2xl font-bold py-4">{heading}</h2>

      <div
        className="flex gap-2 items-center md:gap-6 overflow-scroll scrollbar-none transition-none"
        ref={scrollElement}
      >
        <button
          className="bg-white shadow-md rounded-full absolute left-0 text-lg hidden md:block"
          onClick={scrollLeft}
        >
          <FaAngleLeft />
        </button>
        <button
          className="bg-white shadow-md rounded-full absolute right-0 text-lg hidden md:block"
          onClick={scrollRight}
        >
          <FaAngleRight />
        </button>

        {loading ? (
          loadingList.map((product, index) => {
            return (
              <div
                className="w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] h-40 bg-white rounded-sm shadow flex"
                key={index}
              >
                <div className="bg-slate-200 h-full p-4 min-w-[120px] md:min-w-[145px] animate-pulse">
                  
                </div>
                <div className="p-4 grid w-full gap-2">
                  <h2 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black bg-slate-200 p-1 animate-pulse rounded-full ">
                  
                  </h2>
                  <p className="capitalize text-slate-500 p-1 bg-slate-200 animate-pulse rounded-full">
                   
                  </p>
                  <div>
                    <div className="flex gap-3 w-full ">
                      <p className="text-pink-600 font-medium p-1 bg-slate-200 w-full">
                       
                      </p>
                      <p className="text-slate-500 line-through p-1 bg-slate-200 w-ful">
                        
                      </p>
                    </div>
                    <button className="text-sm text-white px-3 py-0.5 animate-pulse rounded-full w-full bg-slate-200">
                      
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          data.map((product, index) => {
            return (
              <Link to={"product/"+product?._id}
                className="w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] h-40 bg-white rounded-sm shadow flex"
                key={index}
              >
                <div className="bg-slate-200 h-full p-4 min-w-[120px] md:min-w-[145px]">
                  <img
                    src={product.productImage[0]}
                    alt="productImg"
                    className="object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply"
                  />
                </div>
                <div className="p-4 grid">
                  <h2 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black">
                    {product?.productName}
                  </h2>
                  <p className="capitalize text-slate-500">
                    {product?.category}
                  </p>
                  <div>
                    <div className="">
                      <p className="text-pink-600 font-medium">
                        {displayNGNCurrency(product?.sellingPrice)}
                      </p>
                      <p className="text-slate-500 line-through">
                        {displayNGNCurrency(product?.price)}
                      </p>
                    </div>
                    <button className="text-sm bg-pink-600 hover:bg-pink-700 text-white px-3 py-0.5 rounded-full" onClick={(e)=> handleAddToCart(e, product._id)}>
                      Add to Cart
                    </button>
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default HorizontalCardProduct;
