import "./App.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import SummaryApi from "./common";
import Context from "./context";
import { useDispatch } from "react-redux";
import { setUserDetails } from "./store/userSlice";
import { jwtDecode } from "jwt-decode";

function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);

  const fetchUserDetails = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
        console.error("No token found. User is not authenticated.");
        return;
    }

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp < currentTime) {
            console.error("Token has expired. Please log in again.");
            return;
        }

        const dataResponse = await fetch(SummaryApi.current_user.url, {
            method: SummaryApi.current_user.method,
            credentials: "include",
            headers: {
                'Authorization': `Bearer ${token}`, // Correctly include the token in the Authorization header
                'Content-Type': 'application/json',
            },
        });

        if (!dataResponse.ok) { // Use .ok to check for a successful HTTP response
            throw new Error(`No User Response Error: ${dataResponse.status}`);
        }

        const dataApi = await dataResponse.json();
        console.log("Header App Data", dataApi);

        if (dataApi.success) {
            dispatch(setUserDetails(dataApi.data)); // Store user details in state/context
        } else {
            console.error("Failed to retrieve user data:", dataApi.message);
        }
    } catch (error) {
        console.error("Failed to fetch user details", error);
    }
};


  const fetchUserAddToCartCount = async () => {

    const token = localStorage.getItem("authToken"); // Get the token from local storage
  if (!token) {
    console.error("No token found. User is not authenticated.");
    return;
  }

    const response = await fetch(SummaryApi.addToCartProductCount.url, {
      method: SummaryApi.addToCartProductCount.method,
      credentials: "include",
      headers: {
        "Authorization": `Bearer ${token}`, // Include the token in the request headers
        "Content-Type": "application/json",
      },
    });

    const dataApi = await response.json();

    setCartProductCount(dataApi?.data?.count);
  };

  useEffect(() => {
    // User Details
    fetchUserDetails();

    // User Cart Product
    fetchUserAddToCartCount();
  }, []);

  return (
    <Context.Provider
      value={{
        fetchUserDetails,
        cartProductCount,
        fetchUserAddToCartCount,
      }}
    >
      <ToastContainer position="top-center" />
      <Header />
      <main className="min-h-[calc(100vh-120px)] pt-16">
        <Outlet />
      </main>
      <Footer />
    </Context.Provider>
  );
}

export default App;
