import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import AdminEditProduct from "./AdminEditProduct";
import displayNGNCurrency from "../helpers/displayCurrency";

const AdminProductCard = ({ data, key, fetchProduct, deleteProduct }) => {
  const [editProduct, setEditProduct] = useState(false);

  // Handle delete confirmation
  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      deleteProduct(data._id); // Call delete function from props
    }
  };

  return (
    <div className="bg-white p-4 rounded" key={key}>
      <div className="w-40 ">
        <div className="w-32 h-32 flex justify-center items-center">
          <img
            src={data?.productImage[0]}
            alt="Product"
            className="mx-auto object-fill h-full"
          />
        </div>
        <h1 className="text-ellipsis line-clamp-2">{data?.productName}</h1>

        <p className="font-semibold">
          {displayNGNCurrency(data?.sellingPrice)}
        </p>
        <div className="">
          <div>
            <div
              className="w-fit ml-auto p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer"
              onClick={() => setEditProduct(true)}
            >
              <MdEdit />
            </div>
            <div
              className="w-fit mr-auto p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer"
              onClick={handleDelete}
            >
              <MdDelete />
            </div>
          </div>
        </div>
      </div>

      {editProduct && (
        <AdminEditProduct
          ProductData={data}
          onClose={() => setEditProduct(false)}
          fetchProduct={fetchProduct}
        />
      )}
    </div>
  );
};

export default AdminProductCard;
